import { DEMO_PATH } from '../pages/routes';

import { getQueryParams } from './url';

// Get host (without www.)
let host = window.location.hostname.replace('www.', '');

// If we want to try a specific white label, set in session storage (S3 bucket name)
// Only available in local development or on beta/dev sites
if (/(^localhost|^beta\.|^betaapp\.|^alphaapp\.|^matthewdev\.|^oanadev\.|^deliveroobeta\.|^testapp\.)/i.test(host)) {
    host = sessionStorage.whiteLabel || host;
}

// Set host if this is local development and no host has been specified through session storage
if (/(^localhost|^beta\.|^betaapp\.v|^alphaapp\.|^matthewdev\.|^oanadev\.|^testapp\.)/i.test(host)) {
    if (/cctvconnect/.test(host)) {
        host = 'cctvconnect.com';
    } else if (host === sessionStorage.whiteLabel) { // if host specified through session storage is one of the dev sites
        host = sessionStorage.whiteLabel;
    } else {
        host = 'app.videoloft.com';
    }
}

export default host;

// This checks real hostname (not host above)
// Use when needing to use real host regardless of what's in session storage
export const isDevelopment = window.location.hostname.match(/(alphaapp|localhost|matthewdev|oanadev|testapp)/);

export const isTesting = window.location.hostname.match(/(betaapp|testapp)/);

// Check if app is in demo mode
export const isDemo = !!window.location.pathname.match(new RegExp(`^${DEMO_PATH}(/.*)?$`));

export const isCCTVConnect = /cctvconnect\.com/i.test(host);

// Is the web app being accessed via the mobile app?
export const isMobileApp = getQueryParams(window.location.search).app === 'true';